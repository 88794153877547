#faqContent {
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  align-items: center;
}

#faqContent .content {
  position: relative;
  max-width: 50rem;
  background-color: white;
  padding: 0 2rem;
}

#faqContent h2 {
  padding-bottom: 1rem;
  border-bottom: 2px solid #efefef;
}

#faqContent h2:not(:first-of-type) {
  padding-top: 1rem;
}

#faqContent p {
  font-size: 1.1rem;
  text-align: justify;
}

#faqContent p:last-of-type {
  padding-bottom: 2rem;
}
